<template>
  <section class="rims-config-tpms-kit">
    <rimconfig-breadcrumb
      :breadcrumb-items="breadcrumbItems"
    />
    <div
      class="content"
      :style="{ backgroundImage: `url(${require('@/assets/images/Cover1.png')})` }"
    >
      <div class="overlay">
        <div class="textbox">
          <div class="first">
            <h1>{{ $t('langkey.tpms-kits') }}</h1>
          </div>
        </div>
        <div class="search-tpms-kit">
          <h5 class="search-tpms-kit__title">
            {{ $t('langkey.tpms-kits-search') }}
          </h5>
          <v-text-field
            v-model="search"
            :label="$t('langkey.tpms-kits-search-description')"
            solo
            hide-details
            @keyup.enter="onSearchTpmsKit"
          >
            <template #append>
              <span
                v-if="search"
                class="icon__close"
                @click="clearSearch"
              >
                <icon-close color="var(--theme-gray-3)" />
              </span>
              <span
                class="icon__search"
                @click="onSearchTpmsKit"
              >
                <icon-search />
              </span>
            </template>
          </v-text-field>
          <v-btn
            color="primary"
            class="show-all-tpms-kits__button"
            @click="showAllTpmsKits"
          >
            {{ $t('langkey.show-all-tpms-kits') }}
          </v-btn>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
@import '@/styles/pages/rims-config-tpms-kit.scss';
</style>

<script>
import RimconfigBreadcrumb from '@/components/RimconfigBreadcrumb';
import { breadcrumbItems, sliderImages } from './configs.js';
import { mapState } from 'vuex';

export default {
  components: {
    RimconfigBreadcrumb,
  },
  data() {
    return {
      sliderOptions: {
        autoplay: true,
      },
      search: '',
      searchTpmsKitDialog: false,
    };
  },
  computed: {
    ...mapState('rimconfigTpms', ['tpmsKitsOnlyData']),
    breadcrumbItems() {
      return breadcrumbItems;
    },
    sliderImages() {
      return sliderImages;
    },
  },
  methods: {
    clearSearch() {
      this.search = '';
    },
    onSearchTpmsKit() {
      if (this.search.length === 0) {
        return;
      }
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      let payload = {};
      if (this.search.length === 13) {
        payload = {
          eans: this.search,
        };
      } else {
        payload = {
          mans: this.search,
        };
      }
      this.$store.dispatch('rimconfigTpms/loadTpmsKitsOnly', {
        ...payload,
      })
        .then(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
          if (this.tpmsKitsOnlyData.length === 0) {
            this.$store.dispatch('dialog/setShowErrorDialog', {
              status: true,
              title: this.$t('langkey.no-tpms-kits-found-header'),
              message: this.$t('langkey.no-tpms-kits-found-message'),
            });
          } else {
            this.$router.push(
              this.$i18nRoute({
                name: 'rims-config-tpmsKit-overview',
                query: {
                  search: this.search,
                },
              }),
            );
          }
        })
        .catch(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: this.$t('langkey.no-tpms-kits-found-header'),
            message: this.$t('langkey.no-tpms-kits-found-message'),
          });
        });
    },
    showAllTpmsKits() {
      if (!this.search) {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: true,
        });
        this.$store.dispatch('rimconfigTpms/loadTpmsKitsOnly')
          .then(() => {
            this.$router.push(
              this.$i18nRoute({
                name: 'rims-config-tpmsKit-overview',
              }),
            );
          })
          .finally(() => {
            this.$store.dispatch('dialog/setLoadingDialog', {
              status: false,
            });
          });
      }
    },
  },
};
</script>